import styled from "styled-components";
import heronc from "../../images/impact/hero-impacto.png";
import { fonts } from "../styles";

export const HeroSection = styled.header`
  background-image: url("${heronc}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 47rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 80px 0 80px;

  @media (max-width: 600px) {
    padding: 0 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 0 16px;
  }

  .main-landing-container {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
  }

  ::after {
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.15;
    z-index: 1;
  }

  h1 {
    font-size: 6.8rem;
    line-height: 6.4rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    z-index: 2;
    position: relative;
    margin-top: 9.5rem;
  }
`;

export const HeroInfo = styled.section`
  background-color: #163c50;
  padding: 8rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: white;
    font-family: ${fonts.SofiaProSemiBold};
    margin-bottom: 4rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: white;
    font-family: ${fonts.sofiaProRegular};
    max-width: 76rem;
    margin-bottom: 4rem;
  }

  .hero-info-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 5rem;
    margin-top: 10rem;

    @media (max-width: 420px) {
      grid-template-columns: 1fr;
    }

    h5 {
      font-size: 1.6rem;
      line-height: 2rem;
      color: white;
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: white;
      font-family: ${fonts.sofiaProRegular};
    }

    .hero-info-footer-sign figure {
      margin-bottom: 3.2rem;
      max-width: 176px;
      width: 100%;
      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    .hero-info-footer-images {
      display: flex;
      grid-gap: 4rem;
      align-items: center;

      figure {
        max-width: 65px;
        img {
          width: 100%;
          object-fit: scale-down;
        }
      }
    }
  }
`;

export const AboutSection = styled.section`
  padding: 8rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  .about-section {
    h2 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: #003d52;
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 3.6rem;
    }

    > p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #707070;
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 10rem;
      max-width: 78rem;
    }
  }

  .about-list {
    display: flex;
    justify-content: space-between;
    grid-gap: 6rem;
    margin-bottom: 12rem;

    @media (max-width: 920px) {
      flex-wrap: wrap;
    }

    article {
      display: grid;
      align-items: flex-start;
      justify-content: flex-start;
      justify-items: flex-start;
      align-self: flex-start;
    }

    article figure {
      max-height: 9rem;
      height: 100%;
      margin-bottom: 3.2rem;
      img {
        height: 100%;
        object-fit: scale-down;
      }
    }

    article h5 {
      font-size: 1.8rem;
      line-height: 3.2rem;
      color: #003245;
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 2rem;
    }

    article p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #707070;
      font-family: ${fonts.sofiaProRegular};
    }
  }

  .about-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: start;
      grid-gap: 5rem;
    }

    h3 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      font-family: ${fonts.SofiaProSemiBold};
      color: #003d52;
      max-width: 40rem;
    }

    .about-nums {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-gap: 5rem;

      @media (max-width: 580px) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        width: 100%;

        hr {
          width: 100%;
        }
      }

      article {
        margin: 16px 0;
      }

      h5 {
        font-size: 6.6rem;
        line-height: 1;
        font-family: ${fonts.SofiaProBold};
        color: #003d52;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-family: ${fonts.SofiaProMedium};
        color: #707070;
        max-width: 23rem;
      }
    }
  }
`;

export const AcademySection = styled.section`
  background-color: #ebf0f1;
  padding: 8rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px;
  }

  .main-landing-container-row {
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  figure {
    max-width: 448px;
    width: auto;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 532px;
    width: 100%;

    h2 {
      font-size: 1.6rem;
      line-height: 2.9rem;
      color: #003d52;
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 3rem;
    }

    h3 {
      img {
        display: inline;
        margin-bottom: -2rem;
        margin-right: -1rem;
      }
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: #003d52;
      font-family: ${fonts.SofiaProSemiBold};
      max-width: 53.2rem;
      margin-bottom: 4.4rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #707070;
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 6rem;
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: #02c1b3;
      border-radius: 21px;
      height: 42px;
      padding: 0 60px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }
`;

export const ImpactSection = styled.section`
  padding: 9rem 80px 7rem 80px;

  @media (max-width: 600px) {
    padding: 9rem 32px 7rem 32px;
  }

  @media (max-width: 425px) {
    padding: 9rem 16px 7rem 16px;
  }

  .impact-section {
    h2 {
      font-size: 1.6rem;
      line-height: 2.9rem;
      color: #f58220;
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 2rem;
      text-align: center;
    }

    h3 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: #003d52;
      font-family: ${fonts.SofiaProBold};
      text-align: center;
      margin-bottom: 3.2rem;
    }

    > p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #707070;
      font-family: ${fonts.SofiaProMedium};
      text-align: center;
      max-width: 60rem;
      margin: auto;
      margin-bottom: 8rem;
    }
  }

  .impact-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10rem;
    justify-content: space-around;
    max-width: 1040px;
    width: 100%;
    margin: auto;
  }

  .impact-list article {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 28rem;
    width: 100%;

    figure {
      max-width: 150px;
      width: 100%;
      margin-bottom: 2.5rem;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    h5 {
      font-size: 1.8rem;
      line-height: 3.3rem;
      color: #003d52;
      font-family: ${fonts.SofiaProSemiBold};
      text-align: center;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #707070;
      font-family: ${fonts.SofiaProMedium};
      text-align: center;
    }
  }
`;

export const CompanyBSection = styled.section`
  padding: 0 80px;

  @media (max-width: 600px) {
    padding: 0 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px;
  }

  .company-b-card {
    width: 100%;
    padding: 4.4rem 6rem;
    background-color: #ebf0f1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5rem;
    border-radius: 10px;

    @media (max-width: 920px) {
      flex-direction: column;
    }

    figure {
      max-width: 332px;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      h2 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: #003d52;
        font-family: ${fonts.SofiaProBold};
        max-width: 486px;
        width: 100%;
        margin-bottom: 2.8rem;
      }

      h3 {
        font-size: 2.8rem;
        line-height: 4.8rem;
        color: #003d52;
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 2.8rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: #707070;
        font-family: ${fonts.sofiaProRegular};
        margin-bottom: 3rem;
        max-width: 470px;
      }
    }
  }
`;

export const InfoSection = styled.section`
  padding: 16rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 16rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 16rem 16px 8rem 16px;
  }

  .info-section {
    grid-gap: 11rem;
    width: 100%;
  }

  .info-section-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5rem;

    article {
      h2 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: #003d52;
        font-family: ${fonts.SofiaProBold};
        max-width: 453px;
        width: 100%;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: #707070;
        font-family: ${fonts.SofiaProMedium};
        max-width: 453px;
        width: 100%;
        margin-bottom: 2rem;
      }

      a {
        font-size: 1.8rem;
        line-height: 3.4rem;
        color: #003d52;
        font-family: ${fonts.SofiaProSemiBold};
        max-width: 453px;
        width: 100%;
        margin-bottom: 2rem;
        text-decoration: none;
      }
    }

    figure {
      max-width: 424px;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .info-section-item-one {
    @media (max-width: 920px) {
      flex-direction: column;
    }
  }

  .info-section-item-two {
    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }
  }
`;

export const ContactSection = styled.section`
  padding: 10rem 80px 8.5rem 80px;
  background-color: #ebf0f1;

  @media (max-width: 600px) {
    padding: 10rem 32px 8.5rem 32px;
  }

  @media (max-width: 425px) {
    padding: 10rem 16px 8.5rem 16px;
  }

  .contact-section {
    justify-content: space-between;
    grid-gap: 6rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  figure {
    max-width: 205px;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 51.1rem;
    width: 100%;

    h5 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: #003d52;
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 3.2rem;
    }

    p {
      font-size: 2rem;
      line-height: 2.8rem;
      color: #003d52;
      font-family: ${fonts.sofiaProRegular};
      margin-bottom: 3.2rem;

      a {
        color: #f58220;
        text-decoration: none;
      }
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: #f58220;
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }
`;
